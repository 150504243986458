import { render, staticRenderFns } from "./blue-and-orange-card-pr.vue?vue&type=template&id=1670a8f9&scoped=true&"
import script from "./blue-and-orange-card-pr.vue?vue&type=script&lang=js&"
export * from "./blue-and-orange-card-pr.vue?vue&type=script&lang=js&"
import style0 from "./blue-and-orange-card-pr.vue?vue&type=style&index=0&id=1670a8f9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1670a8f9",
  null
  
)

export default component.exports