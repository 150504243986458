<template>
    <div>
      <br>
      <br>

      <div  id="section-3" v-view="cardHandler"   class="col-12 computer  text-center card-horizontal " data-aos="fade-up" data-aos-duration="1500">
        <transition name="fade">
          <div v-if="orangeCard">
          <input  class="input-range blue-card-range" orient="vertical" type="range" v-model="cardKey" min="0" max="10">
            <div :style="`--orangescroll:${orangescroll}%`" class="orange-card d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="1500">
              <div v-if="cardKey >= 2" class="card-text-1" data-aos="fade-up" data-aos-duration="1500">Consultant recrutement spécialisé </div>
              <div v-if="cardKey >= 4" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">IT/ DIGITAL</div>
              <div v-if="cardKey >= 6" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">COMMERCIAL</div>
              <div v-if="cardKey >= 8" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">AGROALIMENTAIRE</div>
              <div v-if="cardKey >= 10" class="card-text-2" data-aos="fade-up" data-aos-duration="1500">RETAIL</div>
            </div>
          </div>
        </transition>
          
          <!-- <div style="height:300px"></div> -->
      <!-- </div> -->

      <!-- <div class="col-12 text-center card-horizontal m-4" data-aos="fade-in" data-aos-duration="1500" > -->
        <!-- <transition name="fade"> -->
          <div v-if="!orangeCard">
            <div v-if="cardKey >= 14" :style="`--bluescroll:${bluescroll}%`"  class="blue-card d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="3000">
              <div v-if="cardKey >= 16" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je parle l'anglais, le français,le malgache</div><br>
              <div  v-if="cardKey >= 18" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je me déplace sur toute la France</div><br>
              <div  v-if="cardKey >= 20" class="card-text-2" data-aos="fade-in" data-aos-duration="1500">Je travaille aisément en télétravail </div><br>
            </div>
            <input  class="input-range orange-card-range" orient="vertical" type="range" v-model="cardKey" min="14" max="20">
          </div>
        <!-- </transition> -->
           
      </div>

      <div   class="col-12 mobile text-center card-horizontal-mobile " data-aos="fade-up" data-aos-duration="1500">

          <div  >
            <div class="orange-card-mobile  d-flex flex-column justify-content-center"  data-aos="fade-in" data-aos-duration="1500">
              <br>
              <div  class="card-text-mobile-1" data-aos="fade-up" data-aos-duration="1500">Consultant recrutement spécialisé </div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">IT/ DIGITAL</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">COMMERCIAL</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">AGROALIMENTAIRE</div>
              <div  class="card-text-mobile-2" data-aos="fade-up" data-aos-duration="1500">RETAIL</div>
            </div>
          </div>
      </div>

      <div class="col-12 text-center mobile card-horizontal-mobile" data-aos="fade-in" data-aos-duration="1500" >
          <div>
            <div  class="blue-card-mobile  d-flex flex-column justify-content-center" data-aos="fade-in" data-aos-duration="1500">
              <br>
              <br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je parle l'anglais, le français,le malgache</div><br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je me déplace sur toute la France</div><br>
              <div class="card-text-mobile-2" data-aos="fade-in" data-aos-duration="1500">Je travaille aisément en télétravail </div><br>
            </div>
          </div>           
      </div>
    </div>
</template>
<script>

export default {
  name: "blue-and-orange-card-pr",
  data() {
    return {
      getdescription: null,
      orangeCard:true,
      cardKey:0,
      orangescroll:40,
      bluescroll:40,
    };
  },
  computed: {},
  watch: {
    justify(data) {
      console.log(data);
    },
  },
  props: {
    title: Array,
    text: Array,
    edit: Boolean,
    image: String,
    editable: Boolean,
    sectionTitle: String,
  },
  methods: {
    format(description) {
      let justify = description.data["paragraph-justify"];
      return justify;
    },
    saveImage(image) {
      this.$emit("change-image-hb", image);
    },
    typography(j, h) {
      return j + " " + h;
    },
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
     scrollOp(operation){
      var supportsPassive = true;
      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if (operation) {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
      }
    },
    preventDefaultForScrollKeys(e){
      // left: 37, up: 38, right: 39, down: 40,
      // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
      var keys = {37: 1, 38: 1, 39: 1, 40: 1};
      if (keys[e.keyCode]) {
        this.preventDefault(e);
        return false;
      }
    },
    preventDefault(e) {
      e.preventDefault();
    },
    cardHandler(e){
      if ( e.percentInView == 1 ) {
        this.scrollOp(true)
        window.addEventListener('wheel', this.cardScroll)

      }
    },
    cardScroll(e){
       if (e.deltaY>0) {
         if (this.cardKey <= 22 ) {
            this.cardKey++
            if (this.cardKey  < 12) {
            this.orangescroll = this.orangescroll + 0.02
            }else{
              this.bluescroll = this.bluescroll + 0.02
            }
          }
       }else{
         if (this.cardKey > -2) {
            this.cardKey--
            if (this.cardKey  < 12) {
            this.orangescroll = this.orangescroll - 0.02
            }else{
              this.bluescroll = this.bluescroll - 0.02
            }
          }
       }
       if (this.cardKey >= 12) {
         this.orangeCard =false
       }else{
         this.orangeCard =true

       }
       if (this.cardKey == -2) {

          this.scrollOp(true)
          window.removeEventListener('wheel',this.preventDefault)
          }
       if (this.cardKey == 23 ) {
          this.scrollOp(true)
          window.removeEventListener('wheel',this.preventDefault)
       }
    }
  },
};
</script>

<style scoped>
.card-horizontal{
  padding-left: 10%;
  padding-right: 10%;
  height: 400px;
}

.card-horizontal-mobile{
  padding: 20px;
}

.input-range {
    -webkit-appearance: none;
    height: 200px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #706d7021;
    border-radius: 25px;
    border: 0px solid #000101;
    width: 3px;
    outline: none;
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    transform: rotate(180deg) scaleX(-1);
    cursor: pointer;
    
  }

  .card-text-1{
      font-family:'Noto Sans';
      font-size: 2rem;
}

.card-text-2{
      font-family:'Noto Sans';
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
      line-height: 40px;

}

.card-text-mobile-1{
      font-family:'Noto Sans';
      font-size: 1.5rem;
}

.card-text-mobile-2{
      font-family:'Noto Sans';
      font-size: 1.5rem;
      font-weight: 500;
      color: #fff;
      line-height: 30px;

}

.orange-card-range{
    position: absolute;
    left: 0;
    top: 50px;
  }

  .orange-card{
    /* position: -webkit-sticky; */
    /* position: sticky; */
    /* top: 0; */
    height:300px;
    background: #f93a00;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    border-radius: 3px;
    background-image:  url("../../assets/images/import/pr/forest-_orange_degrade.png");
    background-size: var(--orangescroll);
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    /* transition: background-size 0.3s ease; */
    
}

.orange-card-mobile{
  
    min-height:300px;
    background-color: #FB6E00;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
     border-radius: 3px;
    background-image:  url("../../assets/images/import/pr/forest-_orange_degrade.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
}

.blue-card{
    /* position: sticky; */
    /* position: -webkit-sticky; */
    /* top: 0; */
    height: 300px;
    background-color: #6295FE;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    margin-top: 0px;
    background-image:  url("../../assets/images/import/pr/forest_blue.png");
    background-size: var(--bluescroll);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    /* transition: background-size 0.3s ease; */

}

.blue-card-mobile{
    min-height: 300px;
    background-color: #6295FE;
    box-shadow: -8px 8px 6px -5px rgba(0,0,0,0.75);
    margin-top: 0px;
    background-image:  url("../../assets/images/import/pr/forest_blue.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
}
  .blue-card-range{
    position: absolute;
    right: 0;
    top: 50px;
    border-radius: 3px;

  }

   .orange-card-range{
    position: absolute;
    left: 0;
    top: 50px;
  }
  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #F93A00;
    height: 3rem;
    width: 5px;
}

</style>